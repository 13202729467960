<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <el-form
        :inline="true"
        status-icon
        label-position="right"
        :model="formInline"
        ref="formInline"
        class="demo-form-inline bgFFF paddingT20 paddingLR20 marginB20"
      >
        <el-form-item label="充电站名称">
          <remote-search
            :config="{
              url: '/acb/2.0/stationIncomeStatistics/stationList',
              method: 'get',
              data: {
                page: 0,
                pageSize: 0,
              },
              key: 'value.list',
              label: 'stationName',
              value: 'stationId',
            }"
            v-model="formInline.stationId"
          ></remote-search>
        </el-form-item>
        <el-form-item :label="$t('searchModule.Merchant_Name')">
          <el-select v-model.trim="formInline.operationId" filterable>
            <el-option label="全部" value=""></el-option>
            <el-option
              :label="value.operationName"
              :value="value.operationId"
              :key="value.operationId"
              v-for="value in operationList"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('searchModule.date')" prop="dateType">
          <el-select v-model.trim="formInline.dateType" filterable size="15" style="width: 80px">
            <el-option label="日" value="1">日</el-option>
            <el-option label="月" value="2">月</el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            unlink-panels
            v-show="formInline.dateType == '1'"
            value-format="yyyy-MM-dd"
            v-model="dateRange"
            :picker-options="pickerOptions"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
          <el-date-picker
            value-format="yyyy-MM"
            v-show="formInline.dateType == '2'"
            unlink-panels
            v-model="monthRange"
            :picker-options="pickerOptions"
            type="monthrange"
            :editable="false"
            :clearable="false"
            range-separator="至"
            start-placeholder="开始月份"
            end-placeholder="结束月份"
          >
          </el-date-picker>
        </el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="searchData()" :loading="loading"
          >{{ $t('button.search') }}</el-button
        >
        <el-button type="primary" plain icon="el-icon-search" @click="clear" :loading="loading"
          >{{ $t('button.reset') }}</el-button
        >
        <el-button
          type="primary"
          icon="el-icon-upload2"
          @click="exportExcelReport()"
          :loading="loading"
          v-if="$route.meta.authority.button.export"
          >导出
        </el-button>
      </el-form>
      <div class="statics">
        <div class="statics-item">
          <div class="item-content graphShadow">
            <div><i class="iconfont iconshoukuanyeji"></i><span class="title">收款合计</span></div>
            <div class="price">{{ moneyTofix(statics.incomeTotal) }}</div>
          </div>
        </div>
        <div class="statics-item">
          <div class="item-content graphShadow">
            <div><i class="iconfont iconshoukuan"></i><span class="title">电费收款合计</span></div>
            <div class="price">{{ moneyTofix(statics.electricTotal) }}</div>
          </div>
        </div>
        <div class="statics-item">
          <div class="item-content graphShadow">
            <div><i class="iconfont icon18"></i><span class="title">服务费收款合计</span></div>
            <div class="price">{{ moneyTofix(statics.seviceTotal) }}</div>
          </div>
        </div>
        <div class="statics-item">
          <div class="item-content graphShadow">
            <div><i class="iconfont iconyue1"></i><span class="title">预约费收款合计</span></div>
            <div class="price">{{ moneyTofix(statics.appointmentTotal) }}</div>
          </div>
        </div>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingT10 paddingB10 marginT20">
        <graphTitle :title="'充值统计详情'" class="marginB20 paddingL20"></graphTitle>
        <el-table
          border
          v-loading="loading"
          :data="tableData"
          class="marginB10"
          style="width: 100%"
        >
          <el-table-column
            align="center"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
        </el-table>
        <div style="text-align: right">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dateFormat, exportExcelNew, moneyTofix } from "@/common/js/public.js";
import remoteSearch from "@/components/remoteSearch/";
import graphTitle from "@/components/graphTitle";

export default {
  name: "paySummaryReport",
  data() {
    return {
      moneyTofix,
      statics: {},
      dateRange: [],
      monthRange: [],
      operationList: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() >= Date.now();
        },
      },
      formInline: {
        stationId: "",
        dateType: "1",
        startTime: "",
        operationId: "",
        endTime: "",
      },
      loading: false,
      tableData: [],
      total: 0,
      page: 1,
      pageSize: 15,
      tableCols: [
        {
          prop: "stationName",
          label: "充电站名称",
          width: "",
        },
        {
          prop: "operationName",
          label: this.$t("list.Merchant_Name"),
          width: "",
        },
        {
          prop: "calc_date",
          label: this.$t("list.date"),
          width: "",
        },
        {
          prop: "money",
          label: "收款总额",
          width: "",
          formatter: (row) => {
            return moneyTofix(row.money);
          },
        },
        {
          prop: "elecmoney",
          label: "电费收款",
          width: "",
          formatter: (row) => {
            return moneyTofix(row.elecmoney);
          },
        },
        {
          prop: "sevicemoney",
          label: "服务费收款",
          width: "",
          formatter: (row) => {
            return moneyTofix(row.sevicemoney);
          },
        },
        {
          prop: "appointmentmoney",
          label: "预约费收款",
          width: "",
          formatter: (row) => {
            return moneyTofix(row.appointmentmoney);
          },
        },
      ],
    };
  },
  methods: {
    initDate() {
      let startDate = this.$moment().subtract(30, "days").format("YYYY-MM-DD");
      let endDate = this.$moment().subtract(1, "days").format("YYYY-MM-DD");
      let startMonth = this.$moment().subtract(11, "months").format("YYYY-MM");
      let endMonth = this.$moment().format("YYYY-MM");
      this.dateRange = [startDate, endDate];
      this.monthRange = [startMonth, endMonth];
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getTable();
    },
    clear() {
      this.initDate();
      this.formInline = {
        stationId: "",
        dateType: "1",
        startTime: "",
        operationId: "",
        endTime: "",
      };
    },
    /* 商户名称的下拉框 */
    getOperationList() {
      let opt = {
        method: "get",
        url: "/acb/2.0/operation/elceNameList",
        data: {
          businessType: 4,
        },
        success: (res) => {
          this.loading = false;
          if (res.state == 0) {
            this.operationList = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    getStatics() {
      this.$axios
        .get("/acb/2.0/stationIncomeStatistics/stationStatistics", {
          data: {
            ...this.formInline,
          },
        })
        .then((res) => {
          this.statics = res.value || {};
        });
    },
    getTable() {
      this.$axios
        .get("/acb/2.0/stationIncomeStatistics/list", {
          data: {
            page: this.page,
            pageSize: this.pageSize,
            ...this.formInline,
          },
        })
        .then((res) => {
          this.tableData = res.value.list || [];
          this.total = res.value && res.value.total * 1;
        });
    },
    searchData() {
      let startTime = "";
      let endTime = "";
      try {
        if (this.formInline.dateType == 1) {
          [startTime = "", endTime = ""] = this.dateRange;
        } else {
          [startTime = "", endTime = ""] = this.monthRange;
        }
      } catch (error) {
        this.$alert("日期不能为空！");
      }

      this.formInline.startTime = startTime;
      this.formInline.endTime = endTime;
      this.getStatics();
      this.getTable();
    },
    exportExcelReport() {
      let startTime = "";
      let endTime = "";
      try {
        if (this.formInline.dateType == 1) {
          [startTime = "", endTime = ""] = this.dateRange;
        } else {
          [startTime = "", endTime = ""] = this.monthRange;
        }
      } catch (error) {
        this.$alert("日期不能为空！");
      }
      exportExcelNew(
        "/acb/2.0/stationIncomeStatistics/export",
        {
          ...this.formInline,
          startTime,
          endTime,
        },
        "post"
      );
    },
  },
  components: {
    remoteSearch,
    graphTitle,
  },
  mounted() {
    this.initDate();
    this.searchData();
    this.getOperationList();
  },
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb
  height 35px
.content
  .searchWrapper
    overflow hidden
    background #EFF2F7
    padding 22px 22px 0
    margin-bottom 20px
  .list
    margin-top 20px
    .el-col
      padding-bottom 18px
      height 320px
      .grid-content
        padding-top 15px
        height 100%
      h2
        font-size 18px
        text-align center
  .statics
    display flex
    padding: 0 20px
  .title
    font-size: 20px
    font-weight 600
    line-height 30px
  .price
    font-size 25px
    font-weight 900
    line-height 50px
  .item-content
    border: 1px solid #bbb
    border-radius 10px
    padding 20px 0;
  .iconfont
    margin-right 20px
    font-size 60px
    color skyblue
    vertical-align middle
  .statics-item
    flex 1 0
    padding 0 15px
    text-align center
</style>
