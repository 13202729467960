var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-select",
    {
      attrs: {
        filterable: "",
        remote: "",
        "default-first-option": "",
        "reserve-keyword": "",
        clearable: "",
        placeholder: "请输入关键词",
        "remote-method": _vm.remoteMethod,
        loading: _vm.loading,
      },
      on: { change: _vm.handleChange },
      model: {
        value: _vm.selected,
        callback: function ($$v) {
          _vm.selected = $$v
        },
        expression: "selected",
      },
    },
    _vm._l(_vm.options, function (item) {
      return _c("el-option", {
        key: item[_vm.config.value],
        attrs: { label: item[_vm.config.label], value: item[_vm.config.value] },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }