<template>
    <el-select
    v-model="selected"
    filterable
    remote
    default-first-option
    reserve-keyword
    clearable
    @change='handleChange'
    placeholder="请输入关键词"
    :remote-method="remoteMethod"
    :loading="loading">
    <el-option
      v-for="item in options"
      :key="item[config.value]"
      :label="item[config.label]"
      :value="item[config.value]">
    </el-option>
  </el-select>
</template>
<script>
export default {
    /**
     * config = {
     *  method, // 请求方式
     *  url, // 请求url
     *  data, // 请求数据
     *  label, // 返回的文字描述
     *  value // 返回的 后台参数
     * }
     */
    props: ["config", 'value'], 
    model: {
        prop: 'value',
        event: 'change'
    },
    watch: {
        value: {
            handler(ne) {
                this.selected = ne
            },
            immediate: true
        }
    },
    data() {
        return {
            options: [],
            selected: '',
            loading: false
        }
    },
    methods: {
        handleChange(selected) {
            this.$emit('change', selected)
        },
        remoteMethod(stationName) {
            this.loading = true;
            let data = this.config.data || {}
            let opt = {
                method: this.config.method || 'post',
                url: this.config.url,
                data: {
                    stationName,
                    ...data,
                },
                success: res => {
                this.loading = false;
                if (res.state == 0) {
                    this.options = this.config.key.split('.').reduce((mock, v) => {
                        return mock[v]
                    }, res);
                } else {
                    this.$alert(res.desc, this.$t('pop_up.Tips'), {
                    confirmButtonText: this.$t('pop_up.Determine')
                    });
                }
                }
            };
      this.$request(opt);
        }
    }
}
</script>
